class ApiErrorJson<T> {
  error: true;
  code: 'FATAL' | 'OPERATIONAL' | 'INVALID';
  body: T;
}

class ApiSuccessJson<T> {
  success: true;
  error: false;
  body: T;
}

export class ApiError<T = string> extends Error {
  code: 'FATAL' | 'OPERATIONAL' | 'INVALID' | 'NOT_FOUND';
  body: T;
  constructor(json: ApiErrorJson<T>) {
    super(String(json.body));
    this.code = json.code;
    this.body = json.body;
  }
  isFatalError() {
    return this.code === 'FATAL';
  }
  isNotFoundError() {
    return this.code === 'NOT_FOUND';
  }
  isValidationError() {
    return this.code === 'INVALID';
  }
  isOperationalError() {
    return this.code === 'OPERATIONAL';
  }

  /**
   * Create operational error, mainly for testing
   * @param {string} message
   * @returns {ApiError<string>}
   */
  static createOperational(message: string): ApiError<string> {
    return new ApiError<string>({
      error: true,
      code: 'OPERATIONAL',
      body: message,
    });
  }

  /**
   * Create fatal error, mainly for testing
   * @param {string} message
   * @returns {ApiError<string>}
   */
  static createFatal(message: string): ApiError<string> {
    return new ApiError<string>({
      error: true,
      code: 'FATAL',
      body: message,
    });
  }
}

/**
 * Parses the response of v3 apis
 * If the response is an error, it throws corresponding {@link ApiError}
 * @param value
 */
export function parseResponse<T, S>(value: ApiErrorJson<S> | ApiSuccessJson<T>): T {
  if (value.error === true) {
    throw new ApiError<S>(value);
  }
  return value.body;
}

/**
 * Translate raised error to user facing message
 * @param {ApiError<any> | any} error
 * @returns {string}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function translateErrorMessage(error: ApiError<any> | any): string {
  if (error && typeof error.message !== 'undefined') {
    return error.message;
  }
  if (error && error instanceof ApiError) {
    if (error.isOperationalError()) {
      return error.body;
    }
    if (error.isValidationError()) {
      return '無効なリクエスト';
    }
    if (error.isFatalError()) {
      return '原因不明のエラー';
    }
  }
  return '原因不明のエラー';
}
