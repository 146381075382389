import { SessionId, SessionTemplateId, SessionVisibility } from '../../commonv3/types/sessions';
import { ISODateTime } from '../../commonv3/types/dates';
import { OnlineLocation, SheLocation } from './location';
import { TokenType } from '../../commonv3/types/tokens';
import { MembershipPlanGroupKey } from './membershipPlans';
import { CoachPosition } from '../../commonv3/types/reservable';

export const SessionType = {
  GROUP_COACHING: 'group_coaching',
  MOKUMOKU: 'mokumoku',
  MULTICREATOR_CAREER_COACHING: 'multicreator_career_coaching',
  INITIAL_MULTICREATOR_CAREER_COACHING: 'initial_multicreator_career_coaching',
  MULTICREATOR_FINAL_REVIEW: 'multicreator_final_review',
  MULTICREATOR_INTERMEDIATE_REVIEW: 'multicreator_intermediate_review',
  ONE_ON_ONE_COACHING: 'one_on_one_coaching',
  LESSON: 'lesson',
  STUDY_GROUP_MEETING: 'study_group_meeting',
  COURSE_PLANNING_MEETING: 'course_planning_meeting',
} as const;
export type SessionType = typeof SessionType[keyof typeof SessionType];

export const CoachingSessionTypeList: SessionType[] = [
  SessionType.GROUP_COACHING,
  SessionType.INITIAL_MULTICREATOR_CAREER_COACHING,
  SessionType.MULTICREATOR_CAREER_COACHING,
];

export enum SessionTemplateKey {
  ONLINE_INITIAL_GROUP_COACHING = 'online_initial_coaching',
  OFFLINE_INITIAL_GROUP_COACHING = 'offline_initial_coaching',
  ONLINE_INITIAL_GROUP_COACHING_V2_FIRST = 'online_initial_coaching_v2_first',
  OFFLINE_INITIAL_GROUP_COACHING_V2_FIRST = 'offline_initial_coaching_v2_first',
  ONLINE_INITIAL_GROUP_COACHING_V2_SECOND = 'online_initial_coaching_v2_second',
  OFFLINE_INITIAL_GROUP_COACHING_V2_SECOND = 'offline_initial_coaching_v2_second',
  ONLINE_INITIAL_GROUP_COACHING_V3_FIRST = 'online_initial_coaching_v3_first',
  OFFLINE_INITIAL_GROUP_COACHING_V3_FIRST = 'offline_initial_coaching_v3_first',
  ONLINE_INITIAL_GROUP_COACHING_V3_SECOND = 'online_initial_coaching_v3_second',
  OFFLINE_INITIAL_GROUP_COACHING_V3_SECOND = 'offline_initial_coaching_v3_second',
  ONLINE_INITIAL_COACHING_WELCOME_WS = 'online_initial_coaching_welcome_ws',
  OFFLINE_INITIAL_COACHING_WELCOME_WS = 'offline_initial_coaching_welcome_ws',
  ONLINE_INITIAL_COACHING_PREP_WORKSHOP = 'online_initial_coaching_prep_workshop',
  OFFLINE_INITIAL_COACHING_PREP_WORKSHOP = 'offline_initial_coaching_prep_workshop', // TODO: オフラインの入学式は使われていないため削除してよい
  ONLINE_SECOND_COACHING = 'online_second_coaching',
  OFFLINE_SECOND_COACHING = 'offline_second_coaching',
  ONLINE_REGULAR_GROUP_COACHING = 'online_regular_coaching',
  OFFLINE_REGULAR_GROUP_COACHING = 'offline_regular_coaching',
  OFFLINE_MOKUMOKU_DESIGN = 'offline_mokumoku_design',
  OFFLINE_MOKUMOKU_WRITING = 'offline_mokumoku_writing',
  OFFLINE_MOKUMOKU_MARKETING = 'offline_mokumoku_marketing',
  ONLINE_BEAUTY_PLAY_ROOM = 'online_beauty_play_room',
  ONLINE_BEAUTY_INITIAL_COACHING_1 = 'online_beauty_initial_coaching_1',
  ONLINE_BEAUTY_INITIAL_COACHING_2 = 'online_beauty_initial_coaching_2',
  ONLINE_BEAUTY_GROUP_COACHING = 'online_beauty_group_coaching',
  ONLINE_LIKES_STUDY_SUPPORT_COACHING = 'online_likes_study_support_coaching',
}

export type SessionIndexView = {
  id: SessionId;
  session_type: SessionType;
  title: string;
  template_id: SessionTemplateId;
  template_name: string;
  template_key: SessionTemplateKey;
  required_token_type: TokenType;
  required_token_amount: number;
  params: SessionParameter[];
  location: SheLocation | OnlineLocation;
  starts_at: ISODateTime;
  ends_at: ISODateTime;
  published_at: ISODateTime;
  active_reservation_count: number;
  capacity: number;
  membership_plan_group_keys: MembershipPlanGroupKey[];
  zoom_url: string | null;
  coach_positions: CoachPosition[];
  visibility: SessionVisibility;
};

export enum SessionParameterKey {
  LABELS = 'labels',
  ZOOM_URL = 'zoom_url',
}

export enum SessionParameterType {
  STRING = 'StringSessionParameter',
  INTEGER = 'IntegerSessionParameter',
  TEXT = 'TextSessionParameter',
}

export type StringSessionParameter<K extends SessionParameterKey> = {
  param_type: SessionParameterType.STRING | SessionParameterType.TEXT;
  key: K;
  value: string;
};

export type SessionParameter =
  | StringSessionParameter<SessionParameterKey.LABELS>
  | StringSessionParameter<SessionParameterKey.ZOOM_URL>;
