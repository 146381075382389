class ApiErrorJson<T> {
  error!: true;
  code!: 'FATAL' | 'OPERATIONAL' | 'INVALID';
  body!: T;
}

export type ApiErrorType = 'FATAL' | 'OPERATIONAL' | 'INVALID' | 'NOT_FOUND' | 'EMAIL_TAKEN';

export class ApiError<T> {
  code: ApiErrorType;
  body: T;
  constructor(json: ApiErrorJson<T>) {
    this.code = json.code;
    this.body = json.body;
  }
  isFatalError() {
    return this.code === 'FATAL';
  }
  isNotFoundError() {
    return this.code === 'NOT_FOUND';
  }
  isValidationError() {
    // エラーコードを分離したがもともと EMAIL_TAKEN は INVALID に含まれていたためどちらも validationError として取り扱う
    return this.code === 'INVALID' || this.code === 'EMAIL_TAKEN';
  }
  isEmailTakenError() {
    return this.code === 'EMAIL_TAKEN';
  }
  isOperationalError() {
    return this.code === 'OPERATIONAL';
  }

  /**
   * Create operational error, mainly for testing
   * @param {string} message
   * @returns {ApiError<string>}
   */
  static createOperational(message: string): ApiError<string> {
    return new ApiError<string>({
      error: true,
      code: 'OPERATIONAL',
      body: message,
    });
  }

  /**
   * Create fatal error, mainly for testing
   * @param {string} message
   * @returns {ApiError<string>}
   */
  static createFatal(message: string): ApiError<string> {
    return new ApiError<string>({
      error: true,
      code: 'FATAL',
      body: message,
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function raiseIfApiError<T, S>(value: any): T {
  if (value === null) {
    return value;
  }
  if (value.error === true) {
    throw new ApiError<S>(value);
  }
  return value;
}

/**
 * Translate raised error to user facing message
 * @param {ApiError<any> | any} error
 * @returns {string}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function translateErrorMessage(error: ApiError<any> | any): string {
  if (error && error instanceof ApiError) {
    if (error.isOperationalError()) {
      return error.body;
    }
    if (error.isValidationError()) {
      return '無効なリクエスト';
    }
    if (error.isFatalError()) {
      return '原因不明のエラー';
    }
  }
  return '原因不明のエラー';
}
