import React from 'react';
import { observer } from 'mobx-react-lite';
import { GlobalLoader } from '../../../mainv2/components/GlobalLoader';
import { Page, PageBody, PageHeader } from '../../components/layout/page';
import { Warning } from '../../../mainv3/components/atoms/membership_plans/Warning';
import './addressChangePane.module.scss';
import { gql } from 'urql';
import { useCurrentUserQuery, UserAddress } from '../../../generated/graphql';
import { UserPersonalForm } from '../../../mainv3/components/molecules/UserPersonal/UserPersonalForm';

gql`
  query CurrentUser {
    currentUser {
      id
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      userAddress {
        id
        zipcode
        prefecture
        city
        address
        building
      }
    }
  }
`;

export const AddressChangePane = observer(() => {
  const [result] = useCurrentUserQuery();
  const { data, fetching } = result;

  const currentUser = data?.currentUser;
  const userAddress = data?.currentUser?.userAddress;

  if (fetching) {
    return <GlobalLoader />;
  }

  const isUpdatable = (currentUserValues) => {
    let result = false;
    const targets = ['firstName', 'firstNameKana', 'lastName', 'lastNameKana', 'phoneNumber', 'userAddress'];
    for (const target of targets) {
      if (currentUserValues[target]) {
        result = true;
        break;
      }
    }
    return result;
  };
  return (
    <Page>
      <PageHeader>本人情報の確認 / 変更</PageHeader>
      <PageBody>
        <>
          {isUpdatable(currentUser) && (
            <Warning>
              ご登録済みの本人情報の変更が必要な場合は、
              <a
                href="https://support.shemoney.jp/faqs/652cfef8effab4001246d8ce/"
                target="_blank"
                styleName="underline"
              >
                こちらのFAQ
              </a>
              ページをご確認ください
            </Warning>
          )}
          <div styleName="user-personal-form">
            <UserPersonalForm
              firstName={currentUser?.firstName as string}
              firstNameKana={currentUser?.firstNameKana as string}
              lastName={currentUser?.lastName as string}
              lastNameKana={currentUser?.lastNameKana as string}
              phoneNumber={currentUser?.phoneNumber as string}
              userAddress={userAddress as UserAddress}
            />
          </div>
        </>
      </PageBody>
    </Page>
  );
});
